import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useContext, useEffect, useState } from 'react';

import Loading from '../components/core/loading';
import SessionContext from '../contexts/session';
import { activeFormId, redirectUserAfterLogin } from '../logic/auth';
import LandingPage from './landing';

interface HomeProps {
    couponResponse?: any,
    query: ParsedUrlQuery
}

const ENVIRONMENT = process.env.NEXT_PUBLIC_NENV;

const skippyEnvironment = new Set(['development', 'staging']);

export default function AppHome(props: HomeProps): JSX.Element {
    const router = useRouter();
    const session = useContext(SessionContext);
    const [clickedSkippy, setClickedSkippy] = useState(false);

    const skippy = clickedSkippy && skippyEnvironment.has(ENVIRONMENT);
    useEffect(() => {
        if (session.user.id && !skippy) {
            redirectUserAfterLogin(session.user, router, undefined, session.user?.formId || activeFormId);
        }
    }, [session.user]);

    return (
        <>
            {(session.user.id && session.initialized) || !session.initialized
                ? <Loading />
                : <LandingPage
                    couponResponse={props.couponResponse}
                    query={props.query}
                    setClickedSkippy={setClickedSkippy}
                />}
        </>
    );
}